<template>
  <div>
    <page-banner>
      <template #banner-txt>
        最新消息
      </template>
      <template #banner-img>
        <img v-lazy="banner" class="img-center">
      </template>
    </page-banner>

<!--    -->

    <div class="level-two tab-control">
      <div class="container"  style="padding-left: 0px;">
        <div class="level-two-container">
          <div class="tab-control" >

            <div class="tab-control-item active">
              <router-link to="/recommend">
                <span>活動推介</span>
                <span class="line"></span>
              </router-link>
            </div>


            <div class="tab-control-item">
              <router-link to="/activityReview">
                <span>活動回顧</span>
                <span class="line"></span>
              </router-link>
            </div>

            <div class="tab-control-item">
              <router-link to="/other">
                <span>其他資訊</span>
                <span class="line"></span>
              </router-link>
            </div>

          </div>
        </div>
      </div>
    </div>

  <!--    -->

    <news-list :news="news"></news-list>
    <pagination v-if="isShow"
        :totalPages="totalPages"
        :perPage="perPage"
        :currentPage="currentPage"
        @pagechanged="onPageChange"
    />

  </div>
</template>

<script>
import pageBanner from "@/components/PageBanner";
import NewsList from "@/components/content/newsList/newsList";
import Pagination from "@/components/Pagination";

import {getRecommend} from "@/network/home";

export default {
  name: "Recommend",
  components:{
    NewsList,
    pageBanner,
    Pagination,
  },
  data(){
    return {
      banner:{},
      news:[],
      isShow:true,
      currentPage: 1,
      totalPages: {},
      perPage:9
    }
  },
  created() {
    this.getRecommend()
  },
  methods:{
    onPageChange(page) {
      this.currentPage = page;
      this.getRecommend();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    getRecommend() {
      getRecommend(this.currentPage).then(res =>{
        this.banner = res.data.data.banner;
        this.totalPages = res.data.data.last_page;
        this.news.splice(0, 9, ...res.data.data.data); //替换数据
        if(res.data.data.data.length <= 0){
          this.isShow = false
        }
       })
    }
  }

}
</script>

<style scoped>

</style>